// IE からアクセスされた時のメッセージ
// index.html にベタ書きすると丸見えで格好が悪いので script に分離
// build 時に minify されるので分かりやすさ重視の書き方で OK

(function () {
    // document.documentMode は IE にしかない。
    // UserAgent での判定より確実で簡単。
    if (!document.documentMode) return;

    // DOM を組み立てる
    const sorryBlock = document.createElement('DIV');
    const br = document.createElement('BR');
    const japaneseParagraph = document.createElement('P');
    japaneseParagraph.appendChild(document.createTextNode('まことに申し訳ございません。'));
    japaneseParagraph.appendChild(br.cloneNode());
    japaneseParagraph.appendChild(document.createTextNode('Internet Explorer からは QR コード申請システムをご利用になれません。'));
    japaneseParagraph.appendChild(br.cloneNode());
    japaneseParagraph.appendChild(document.createTextNode('最新版の Microsoft Edge、Google Chrome、Firefox のいずれかをご利用ください。'));
    sorryBlock.appendChild(japaneseParagraph);

    const englishParagraph = document.createElement('P');
    englishParagraph.appendChild(document.createTextNode('We are so sorry but you cannot use QR code requesting system with Internet Explorer.'));
    englishParagraph.appendChild(br.cloneNode());
    englishParagraph.appendChild(document.createTextNode('Please use latest version of Microsoft Edge, Google Chrome, or Firefox.'));
    sorryBlock.appendChild(englishParagraph);

    // style を設定する
    sorryBlock.setAttribute('style',
        'font-family:sans-serif;'
        + 'font-size:1.2em;'
        + 'line-height:1.5em;'
        + 'text-align:center;'
        + 'padding-top:5%;');

    // body に insert する
    document.body.insertBefore(sorryBlock, document.body.lastChild);
})();